// extracted by mini-css-extract-plugin
export var root = "PlasmicHomeCta-module--root--Zw6e1";
export var box__gXLg = "PlasmicHomeCta-module--box__gXLg--aQtD9";
export var box__ovTcs = "PlasmicHomeCta-module--box__ovTcs--2XH4p";
export var box__yBNQ = "PlasmicHomeCta-module--box__yBNQ--WQgPZ";
export var box__mgcW = "PlasmicHomeCta-module--box__mgcW--2YAgE";
export var textbox = "PlasmicHomeCta-module--textbox--2Wlpu";
export var linkButton = "PlasmicHomeCta-module--linkButton--2vz6n";
export var box___2OOaZ = "PlasmicHomeCta-module--box___2OOaZ--14D5T";
export var box__blWux = "PlasmicHomeCta-module--box__blWux--JJTqM";
export var svg = "PlasmicHomeCta-module--svg--nLAA5";
// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatures-module--root--1GOzQ";
export var box__m2GfA = "PlasmicFeatures-module--box__m2GfA--2Tzmx";
export var header = "PlasmicFeatures-module--header--1Ld5D";
export var section = "PlasmicFeatures-module--section--AVnp2";
export var box__uanI4 = "PlasmicFeatures-module--box__uanI4--3tUQu";
export var box__kszUu = "PlasmicFeatures-module--box__kszUu--YO3Bz";
export var box__bTwGy = "PlasmicFeatures-module--box__bTwGy--1d960";
export var box__o2U6A = "PlasmicFeatures-module--box__o2U6A--3aIEV";
export var bullet__aPyUw = "PlasmicFeatures-module--bullet__aPyUw--Glqro";
export var bullet__dcx46 = "PlasmicFeatures-module--bullet__dcx46--XHCdh";
export var bullet___98NRk = "PlasmicFeatures-module--bullet___98NRk--3B2cm";
export var box___9H5R = "PlasmicFeatures-module--box___9H5R--XUaNz";
export var box__meAwh = "PlasmicFeatures-module--box__meAwh--39S4i";
export var box__nrT40 = "PlasmicFeatures-module--box__nrT40---byKZ";
export var img__liM4 = "PlasmicFeatures-module--img__liM4--9QKNZ";
export var img__sv0Qd = "PlasmicFeatures-module--img__sv0Qd--3FdOG";
export var box__yfCp = "PlasmicFeatures-module--box__yfCp--1mBNK";
export var box__iHyOp = "PlasmicFeatures-module--box__iHyOp--2-GO5";
export var img__loC1F = "PlasmicFeatures-module--img__loC1F--1iZ1y";
export var img__kJ7G = "PlasmicFeatures-module--img__kJ7G--58igT";
export var box__s6Wgg = "PlasmicFeatures-module--box__s6Wgg--3a4lX";
export var box__csWsW = "PlasmicFeatures-module--box__csWsW--20xiR";
export var box__bDvl4 = "PlasmicFeatures-module--box__bDvl4--21n_f";
export var bullet__hivOm = "PlasmicFeatures-module--bullet__hivOm--39UVK";
export var bullet__wCbIj = "PlasmicFeatures-module--bullet__wCbIj--xNWpl";
export var bullet__zGxy = "PlasmicFeatures-module--bullet__zGxy--938tQ";
export var box__s8IA5 = "PlasmicFeatures-module--box__s8IA5--2pHyh";
export var box__uEFr = "PlasmicFeatures-module--box__uEFr--1J-MD";
export var homeCta = "PlasmicFeatures-module--homeCta--3_pJJ";
export var footer = "PlasmicFeatures-module--footer--2Pg4E";